import React, { useEffect, useState, useMemo } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { navigate } from "gatsby";
import { Link, FormattedMessage, useIntl } from "gatsby-plugin-intl";
import { Transition } from "@tailwindui/react";
import { useLocation } from "@reach/router";
import _split from "lodash/split";
import { ApolloProvider } from "@apollo/client";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { isLoggedIn } from "../components/app/services/auth";
import { useResetPasswordMutation } from "../components/app/generated/graphql";
import client from "../gatsby-theme-apollo/client";

const ResetPasswordPage: React.FC = () => {
  const lang = useIntl();

  const ValidationSchema = useMemo(() => {
    return Yup.object().shape({
      password: Yup.string().required(lang.formatMessage({ id: "required" })),
    });
  }, [lang]);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showAlert, setShowAlert] = useState(false);
  const [resetPassword, { data, loading, error }] = useResetPasswordMutation();
  const [token, setToken] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    if (data && data.resetPassword) {
      if (data.resetPassword.success) {
        // show success alert
        setShowAlert(true);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(data.resetPassword.message);
      }
    }

    if (error) {
      console.log(error);
      setShowErrorMessage(true);
      setErrorMessage(error.message);
    }

    if (loading) {
      console.log(loading);
    }
  }, [data, error, loading]);

  useEffect(() => {
    if (location && location.search) {
      const result = _split(location.search, "=", 2);
      if (result.length > 1) {
        setToken(result[1]);
      }
    }
  }, [location]);

  if (isLoggedIn()) {
    navigate(`/app`);
  }

  return (
    <ApolloProvider client={client}>
      <Layout>
        <SEO
          title="Forgot Password"
          description="Forgot password to your GivFast account."
        />
        <Transition
          show={showAlert}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div>
            <div className="rounded-md bg-green-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-green-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm leading-5 font-medium text-green-800">
                    <FormattedMessage id="sent_msg" />
                  </p>
                  <button onClick={() => navigate("/login")} className="underline px-2 py-1.5 rounded-md text-sm leading-5 font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:bg-green-100 transition ease-in-out duration-150">
                      <FormattedMessage id="reset_password_msg" />
                    </button>
                </div>
                {/* <div className="ml-auto pl-3">
                  <div className="-mx-1.5 -my-1.5">
                    <button
                      type="button"
                      onClick={() => setShowAlert(false)}
                      className="inline-flex rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:bg-green-100 transition ease-in-out duration-150"
                      aria-label="Dismiss"
                    >
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </Transition>
        <Transition
          show={showErrorMessage}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div>
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <h3 className="text-sm leading-5 font-medium text-red-800">
                    <FormattedMessage id="oops" />
                  </h3>
                  <div className="mt-2 text-sm leading-5 text-red-700">
                    <ul className="list-disc pl-5">
                      <li>
                        {errorMessage}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ml-auto pl-3">
                  <div className="-mx-1.5 -my-1.5">
                    <button
                      type="button"
                      onClick={() => setShowErrorMessage(false)}
                      className="inline-flex rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:bg-red-100 transition ease-in-out duration-150"
                      aria-label="Dismiss"
                    >
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <div className="py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
          <div className="relative max-w-xl mx-auto">
            <div className="text-center">
              <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                <FormattedMessage id="label_reset_password" />
              </h2>
              <p className="mt-4 text-lg leading-6 text-gray-500">
                <FormattedMessage id="label_reset_password_2" />
              </p>
            </div>
            <div className="mt-12">
              <Formik
                initialValues={{ password: "" }}
                validationSchema={ValidationSchema}
                onSubmit={(values, actions) => {
                  try {
                    resetPassword({
                      variables: {
                        password: values.password,
                        token,
                        clientId: process.env.GIVFAST_API_CLIENT_ID,
                      },
                    });
                  } catch (error) {
                    console.error(error);
                    setErrorMessage(error.message);
                    setShowErrorMessage(true);
                  }
                  actions.setSubmitting(false);
                  actions.resetForm();
                }}
              >
                {({ isSubmitting, errors }) => (
                  <Form>
                    {/* Password Field */}
                    <div className="mt-6">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        <FormattedMessage id="label_password" />
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <Field
                          type="password"
                          name="password"
                          className={`form-input block w-full sm:text-sm sm:leading-5 ${
                            errors.password
                              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                              : ""
                          }`}
                        />
                        {/* {errors.password && (
                                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <svg
                                                        className="h-5 w-5 text-red-500"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path
                                                        fillRule="evenodd"
                                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                                        clipRule="evenodd"
                                                        />
                                                    </svg>
                                                    </div>
                                                )} */}
                      </div>
                      <ErrorMessage name="password">
                        {(msg: string): React.ReactNode => (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="password-error"
                          >
                            {msg}
                          </p>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="mt-6">
                      <button
                        type="submit"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:border-teal-700 focus:shadow-outline-indigo active:bg-teal-700 transition duration-150 ease-in-out capitalize"
                        disabled={loading}
                      >
                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                          {(loading || isSubmitting) && !error && (
                            <svg
                              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          )}
                        </span>
                        <FormattedMessage id="submit" />
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Layout>
    </ApolloProvider>
  );
};

export default ResetPasswordPage;
